import React, { FC } from 'react'
import classnames from 'classnames'
import {
	container,
	titleHero,
	titleSubHero,
	txtHorizontal,
	txtVertical,
	txtHero,
	txtSubHero,
} from './Title.module.scss'

type Props = {
	title: string
	titleSub: string
	tag?: 'h1' | 'h2'
	horizontal?: boolean
	className?: string
}

export const Title: FC<Props> = ({ title, titleSub, tag = 'h2', horizontal = true, className }) => {
	const Tag = tag

	const headingClassName = classnames({ [titleHero]: tag === 'h1', [titleSubHero]: tag === 'h2' }, className)
	const txtClassName = classnames({
		[txtHorizontal]: horizontal,
		[txtVertical]: !horizontal,
		[txtHero]: tag === 'h1',
		[txtSubHero]: tag === 'h2',
	})

	return (
		<div className={classnames(container, className)}>
			<Tag className={headingClassName}>{title}</Tag>
			<p className={txtClassName}>{titleSub}</p>
		</div>
	)
}
