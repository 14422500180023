import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

type Props = {
	title: string
	description: string
	image?: string
	article?: boolean
	top?: boolean
}

export const SEO = ({ title, description, image, top }: Props) => {
	const { pathname } = useLocation()
	const { site } = useStaticQuery(query)

	const { defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage } = site.siteMetadata

	const seo = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		image: `${siteUrl}${image || defaultImage}`,
		url: `${siteUrl}${pathname}`,
	}

	return (
		<Helmet
			htmlAttributes={{
				lang: 'ja',
			}}
			title={seo.title}
			titleTemplate={top ? '%s | 外食にテクノロジーを' : titleTemplate}
			meta={[
				{
					name: `description`,
					content: seo.description,
				},
				{
					name: `image`,
					content: seo.image,
				},
				{
					property: `og:title`,
					content: seo.title,
				},
				{
					property: `og:description`,
					content: seo.description,
				},
				{
					property: `og:image`,
					content: seo.image,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary_large_image`,
				},
				{
					name: `twitter:title`,
					content: seo.title,
				},
				{
					name: `twitter:description`,
					content: seo.description,
				},
				{
					name: `twitter:twitter:image`,
					content: seo.image,
				},
			]}
		/>
	)
}

const query = graphql`
	query SEO {
		site {
			siteMetadata {
				defaultTitle: title
				titleTemplate
				defaultDescription: description
				siteUrl: url
				defaultImage: image
				twitterUsername
			}
		}
	}
`
